import React, { useEffect } from "react"

// Libraries
import AOS from "aos"
import "aos/dist/aos.css"
import { graphql } from "gatsby"

// Layout
import Layout from "layouts/layout-primary"

// Libraries
import { useTranslation } from "gatsby-plugin-react-i18next"

// Context
import ContextConsumer from "components/context/"

// Components
import Seo from "components/seo/"

// Sections
import Hero from "sections/culture/hero"
import Together from "sections/culture/together"
import Motion from "sections/culture/motion"
import Team from "sections/culture/team"
import SocialMedia from "sections/culture/social-media"
import Careers from "sections/culture/careers"


const Culture = () => {
  useEffect(() => {
    AOS.init({
      once: true,
    })
  })

  const { t } = useTranslation()

  return (
    <Layout>
      <ContextConsumer>
        {({ data, set }) => {
          if (!data.useYellowFooter) {
            set({ useYellowFooter: true })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t("Culture - Indicius Design Agency")}
        description={t(
          "We care about our culture. Share knowledge. Accountability matters. Be positive. Cultivate autonomy. Curiosity is key. Constant improvement. Common sense."
        )}
      />
      <Hero />
      <Together />
      <Motion />
      <Team />
      <Careers />
      <SocialMedia />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Culture
