import React from "react"

// Libraries
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"

//Libraries
import { Trans } from "gatsby-plugin-react-i18next"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

// Components
import Container from "components/container/"
import Observer from "components/observer/"

const StyledTeam = styled.section`
  padding-top: 80px;
  background-color: ${colors.midnight};
  color: ${colors.white};

  ${breakpoint.medium`
    padding-top: 180px;
  `}

  h1 {
    max-width: 928px;
    margin: 0 auto 80px auto;
    font-family: ${fonts.secondary};
    font-weight: 400;
    text-align: center;

    ${breakpoint.medium`
      margin-bottom: 160px;
    `}
  }

  .team-member {
    ${breakpoint.medium`
      margin-bottom: 96px !important;
    `}
  }
`

const StyledHeading = styled.h1`
  max-width: 928px;
  margin: 0 auto 80px auto;
  font-family: ${fonts.secondary};
  font-weight: 400;
  text-align: center;

  ${breakpoint.medium`
    margin-bottom: 160px;
  `}

  span {
    position: relative;
    z-index: 10;

    &::before {
      content: "";
      width: ${props => (props.visible ? `calc(100% + 16px)` : `0`)};
      height: 30%;
      position: absolute;
      bottom: 8px;
      left: -8px;
      background-color: ${colors.indiblue};
      border-radius: 32px;
      transform: rotate(-1deg);
      transition: all 0.3s ease 0.6s;
      z-index: -1;
    }
  }
`

const StyledTeamMember = styled.div`
  text-align: center;

  &:hover {
    .team-member__image {
      .image {
        opacity: 0;
      }

      .image-hover {
        opacity: 1;
      }
    }

    .team-member__info {
      .position {
        opacity: 0;
      }

      .nickname {
        opacity: 1;

        .strikethrough {
          &::before {
            width: calc(100% + 16px);
          }
        }
      }
    }
  }

  .team-member__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;

    .image {
      transition: all 0.3s ease;
    }

    .image-hover {
      position: absolute !important;
      top: 0;
      opacity: 0;
      transition: all 0.3s ease;
    }
  }

  .team-member__info {
    position: relative;

    .name {
      margin-bottom: 12px;
      color: ${colors.supernova};
      font-weight: 400;
    }

    .position {
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .nickname {
      position: absolute;
      right: 0;
      bottom: 3px;
      left: 0;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      opacity: 0;
      transition: all 0.3s;
      z-index: 12;

      .strikethrough {
        display: inline-block;

        &::before {
          height: 20px;
          bottom: 4px;
          background-color: ${colors.watermelon};
          z-index: -1;
        }
      }
    }
  }
`

const TeamMember = props => (
  <StyledTeamMember>
    <div className="team-member__image">
      <GatsbyImage
        className="image"
        image={getImage(props.image)}
        alt={props.name}
      />
      <GatsbyImage
        className="image-hover"
        image={getImage(props.imageHover)}
        alt={props.name}
      />
    </div>
    <div className="team-member__info">
      <h3 className="name">{props.name}</h3>
      <p className="position">{props.position}</p>
      <div className="nickname">
        <p className="strikethrough">{props.nickname}</p>
      </div>
    </div>
  </StyledTeamMember>
)

class Heading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
  }

  handleVisibility = () => {
    if (!this.state.isVisible) {
      this.setState({
        isVisible: true,
      })
    }
  }

  render = props => (
    <Observer onVisible={this.handleVisibility} threshold="1.0">
      <StyledHeading visible={this.state.isVisible}>
        {this.props.children}
      </StyledHeading>
    </Observer>
  )
}

export default () => {
  const {
    contentfulCulture: { teamMember: teamMembers },
  } = useStaticQuery(graphql`
    query {
      contentfulCulture(title: { eq: "Culture" }) {
        id
        teamMember {
          name
          role
          nickname
          memoji {
            gatsbyImageData(width: 96, quality: 100)
          }
          memojiOnHover {
            gatsbyImageData(width: 96, quality: 100)
          }
        }
      }
    }
  `)

  return (
    <StyledTeam>
      <Container data-aos="fade" data-aos-duration="600">
        <Heading>
          <Trans>We're humans helping humans</Trans>
          <br />
          <span>
            {" "}
            <Trans>meet their goals</Trans>
          </span>
        </Heading>

        <div className="row">
          {teamMembers &&
            teamMembers.map(teamMember => (
              <div className="team-member col-6 col-sm-4 col-md-3 mb-4 mb-md-5">
                <TeamMember
                  image={teamMember.memoji}
                  imageHover={teamMember.memojiOnHover}
                  name={teamMember.name}
                  position={teamMember.role}
                  nickname={teamMember.nickname}
                />
              </div>
            ))}
        </div>
      </Container>
    </StyledTeam>
  )
}
