import React from "react"
import styled from "styled-components"

// Utils
import { colors } from "utils/variables/"

const StyledDropdown = styled.div`
  max-height: ${props => (props.active ? "500px" : "92px")};
  position: relative;
  transition: all 0.3s;
  overflow: hidden;

  .dropdown__button {
    width: 100%;
    display: block;
    padding-bottom: 20px;
    text-align: left;
    cursor: pointer;

    h3 {
      margin-bottom: 8px;
      color: ${colors.supernova};
      font-weight: 400;
    }

    .subtitle {
      color: ${colors.white};
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .dropdown__content {
    padding-top: 12px;
    padding-bottom: 40px;
    font-size: 18px;
    transition: height 0.3s;
    overflow: hidden;
  }

  .dropdown__line {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::before {
      content: "";
      width: calc(100% - 32px);
      height: 1px;
      background-color: ${colors.supernova};
    }

    .indicator {
      width: 24px;
      height: 24px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${colors.supernova};
      color: ${colors.midnight};
      border-radius: 50%;
      cursor: pointer;


      span {
        width: 12px;
        height: 2px;
        background-color: ${colors.midnight};

        &:last-child {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          transform: ${props =>
    props.active ? "rotate(0deg)" : "rotate(90deg)"};
          transition: all 0.3s;
        }
      }
    }
  }
`

class Dropdown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  handleClick = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }))
  }

  render = props => (
    <StyledDropdown active={this.state.isOpen}>
      <button className="dropdown__button" onClick={this.handleClick}>
        <h3>{this.props.title}</h3>
        <p className="subtitle">{this.props.subtitle}</p>
      </button>
      <div className="dropdown__content">{this.props.children}</div>
      <div className="dropdown__line">
        <button className="indicator" onClick={this.handleClick}>
          <span></span>
          <span></span>
        </button>
      </div>
    </StyledDropdown>
  )
}

export default Dropdown
