import React from "react"
import styled from "styled-components"

// Components
import Container from "components/container"
import Button from "components/button"

// Utils
import { colors } from "utils/variables"
// import breakpoints from "utils/breakpoints"

//Libraries
import { useTranslation } from "gatsby-plugin-react-i18next"

// Images
import CareersCtaImage from "assets/images/careers/careers-cta.gif"
import breakpoints from "utils/breakpoints"

const StyledCareersCta = styled.section`
  background-color: ${colors.midnight};
  padding: 120px 0 80px 0;

  ${breakpoints.medium`
    padding: 176px 0;
  `}

  .image__section {
    margin: 0 auto 56px auto;
    background-color: #0b345b;
    padding: 32px 0 40px 0;
    max-width: 256px;
    border-radius: 3px;

    ${breakpoints.medium`
    margin-bottom: 0;
    `}

    img {
      mix-blend-mode: lighten;
    }

    h4:last-child {
      position: relative;
      margin: 0 auto;
      border-radius: 20px;

      span {
        position: relative;
        z-index: 1;
      }

      &::before {
        content: "";
        position: absolute;
        width: calc(100% + 16px);
        height: calc(100% - 4px);
        top: 2px;
        left: -8px;
        background-color: ${colors.midnight};
        border-radius: 16px;
        transform: matrix(0.99, -0.04, 0.1, 1, 0, 0);
        z-index: 0;
      }
    }
  }
`

const CareersCta = () => {
  const { t } = useTranslation()
  return (
    <StyledCareersCta>
      <Container>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4">
            <div className="image__section d-flex flex-column align-items-center">
              <img src={CareersCtaImage} alt="Culture" />
              <h3 className="color--supernova mt-1">You</h3>
              <h4 className="color--white mt-3">Open position</h4>
              <h4 className="color--cloudy mt-3 text-center">
                <span>Talented</span>
              </h4>
            </div>
          </div>
          <div className="col-12 col-md-5 d-flex align-items-center">
            <div className="text-center text-md-start">
              <h2 className="color--white">
                Want to work on a versatile team of passionate creatives?
              </h2>

              <Button
                className="mt-3"
                to="/careers/"
                text={t("View Positions")}
                backgroundColor={colors.supernova}
                color={colors.midnight}
                hoverBackgroundColor={colors.white}
                hoverColor={colors.midnight}
              />
            </div>
          </div>
        </div>
      </Container>
    </StyledCareersCta>
  )
}

export default CareersCta
