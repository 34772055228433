import React from "react"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

//Libraries
import { Trans } from "react-i18next"

// Components
import Container from "components/container/"

// Images
import HeroImage from "assets/images/culture/culture-hero-image.gif"

const StyledHero = styled.header`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.midnight};
  text-align: center;

  h1 {
    position: relative;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: ${colors.supernova};
    -webkit-text-fill-color: transparent;
    font-size: 105px;
    text-transform: lowercase;
    z-index: 10;

    ${breakpoint.large`
      font-size: 175px;
    `}

    ${breakpoint.extraLarge`
      font-size: 200px;
    `}
  }

  .hero__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    z-index: 1;

    img {
      max-height: 90vh;
    }
  }
`

const Hero = () => {
  return (
    <StyledHero>
      <Container>
        <h1>
          <Trans>Our Culture</Trans>
        </h1>
        <div className="hero__image">
          <img src={HeroImage} alt="Culture" />
        </div>
      </Container>
    </StyledHero>
  )
}

export default Hero
