import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

// Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Components
import Container from "components/container/"
import Observer from "components/observer/"

// Icons
import Share from "assets/icons/culture/together/share-knowledge.svg"
import Accountability from "assets/icons/culture/together/accountability.svg"
import Positivity from "assets/icons/culture/together/positive.svg"
import Autonomy from "assets/icons/culture/together/autonomy.svg"
import Curiosity from "assets/icons/culture/together/curiosity.svg"
import Improvement from "assets/icons/culture/together/improvement.svg"
import CommonSense from "assets/icons/culture/together/common-sense.svg"

const StyledTogether = styled.section`
  padding: 80px 0;
  background-color: ${colors.midnight};
  color: ${colors.white};

  ${breakpoint.medium`
    padding: 160px 0;
  `}

  .scrolling-area {
    display: flex;
    overflow-x: auto;
    cursor: none;

    &::-webkit-scrollbar {
      display: none;
    }

    #dragCursor {
      display: none;
      pointer-events: none;

      ${breakpoint.large`
        width: 88px;
        height: 88px;
        background-color: ${colors.midnight};
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        opacity: 0;
        mix-blend-mode: multiply;
      `}

      h4 {
        color: ${colors.white};
        font-weight: 700;
        mix-blend-mode: difference;
      }
    }
  }
`

const StyledHeading = styled.h1`
  max-width: 928px;
  margin: 0 auto 80px auto;
  font-family: ${fonts.secondary};
  font-weight: 400;
  text-align: center;

  ${breakpoint.medium`
    margin-bottom: 160px;
  `}

  span {
    position: relative;
    z-index: 10;

    &::before {
      content: "";
      width: ${props => (props.visible ? `100%` : `0`)};
      height: 30%;
      position: absolute;
      bottom: 8px;
      left: 8px;
      background-color: ${colors.indiblue};
      border-radius: 32px;
      transform: rotate(-1deg);
      transition: all 0.3s ease 0.6s;
      z-index: -1;
    }
  }
`

class Heading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
  }

  handleVisibility = () => {
    if (!this.state.isVisible) {
      this.setState({
        isVisible: true,
      })
    }
  }

  render = props => (
    <Observer onVisible={this.handleVisibility} threshold="1.0">
      <StyledHeading visible={this.state.isVisible}>
        {this.props.children}
      </StyledHeading>
    </Observer>
  )
}

const StyledCard = styled.div`
  max-width: 352px;
  flex-shrink: 0;
  padding: 32px;
  margin: 0 16px;
  background-color: ${props => props.backgroundColor};
  color: ${colors.midnight};
  font-size: 24px;
  user-select: none;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .card__icon {
    max-height: 100px;
    margin-bottom: 72px;
    pointer-events: none;
  }

  h3 {
    max-width: 250px;
    margin-bottom: 16px;
    font-weight: 500;
  }
`

const Card = props => (
  <StyledCard
    backgroundColor={props.backgroundColor}
    isMouseOver={props.isMouseOver}
  >
    <div className="card__icon">
      <img src={props.icon} alt="props.title" />
    </div>

    <h3>{props.title}</h3>
    <p>{props.description}</p>
  </StyledCard>
)

const Together = props => {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     interval: null,
  //     isMouseOver: false,
  //     isMouseDown: false,
  //     cursorPosition: 0,
  //     scrollPosition: 0,
  //   }
  // }

  const [intervalo, setnewInterval] = useState(null)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [cursorPosition, setCursorPosition] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)

  const ref = useRef()

  useEffect(() => {
    playCarousel()

    return () => {
      clearInterval(intervalo)
    }
  }, [])

  const playCarousel = () => {
    var interval = setInterval(() => {
      if (ref.current) {
        ref.current.scrollLeft = ref.current.scrollLeft + 1
      }
    }, 30)

    if (intervalo === null) {
      setnewInterval(interval)
    }
  }

  const pauseCarousel = () => {
    clearInterval(intervalo)
    setnewInterval(null)
  }

  const handleMouseOver = () => {
    if (!isMouseOver) {
      setIsMouseOver(true)
    }
    pauseCarousel()
  }

  const handleMouseLeave = () => {
    if (isMouseOver) {
      setIsMouseOver(false)
    }

    var cursor = document.getElementById("dragCursor")
    cursor.style.opacity = 0

    playCarousel()
  }

  const handleMouseDown = event => {
    var newCursorPosition = event.pageX - ref.current.offsetLeft
    var newScrollPosition = ref.current.scrollLeft

    // setIsMouseDown({
    //   isMouseDown: true,
    //   cursorPosition: newCursorPosition,
    //   scrollPosition: newScrollPosition,
    // })
    setIsMouseDown(true)
    setCursorPosition(newCursorPosition)
    setScrollPosition(newScrollPosition)
  }

  const handleMouseUp = () => {
    setIsMouseDown(false)
  }

  const handleMouseMove = event => {
    if (isMouseDown) {
      event.preventDefault()
      var x = event.pageX - ref.current.offsetLeft
      var walk = x - cursorPosition
      ref.current.scrollLeft = scrollPosition - walk
    }

    // Cursor movement
    var xCoord = event.pageX
    var yCoord = event.pageY

    // Follows cursor
    var cursor = document.getElementById("dragCursor")
    cursor.style.opacity = 0.5
    cursor.style.top = yCoord - 50 + "px"
    cursor.style.left = xCoord - 50 + "px"
  }

  const BePositive = () => (
    <React.Fragment>
      <Trans>Be</Trans>
      <br />
      <Trans>Positive</Trans>
    </React.Fragment>
  )

  const { t } = useTranslation()

  return (
    <StyledTogether>
      <Container>
        <Heading>
          <Trans>We build </Trans>
          <span>
            <Trans> together</Trans>
          </span>
        </Heading>
      </Container>

      <div
        className="scrolling-area"
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        onMouseOver={handleMouseOver}
        onFocus={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        ref={ref}
        role="button"
        aria-hidden="true"
      >
        <div id="dragCursor">
          <h4>
            <Trans>DRAG</Trans>
          </h4>
        </div>
        <Card
          title={t("Share knowledge")}
          description={t("Be generous with what you know.")}
          backgroundColor={colors.indiblue}
          icon={Share}
        />
        <Card
          title={t("Accountability matters")}
          description={t("Always take ownership of what you’re doing.")}
          backgroundColor={colors.cream}
          icon={Accountability}
        />
        <Card
          title={<BePositive />}
          description={t("Try to remain calm when problems arise.")}
          backgroundColor={colors.watermelon}
          icon={Positivity}
        />
        <Card
          title={t("Cultivate autonomy")}
          description={t("But also know when to ask for help.")}
          backgroundColor={colors.supernova}
          icon={Autonomy}
        />
        <Card
          title={
            <span>
              <Trans>Curiosity</Trans>
              <br />
              <Trans>is key</Trans>
            </span>
          }
          description={t("Strive to be up to date on current industry trends.")}
          backgroundColor={colors.indiblue}
          icon={Curiosity}
        />
        <Card
          title={t("Constant Improvement")}
          description={t("Going the extra mile really makes a difference.")}
          backgroundColor={colors.cream}
          icon={Improvement}
        />
        <Card
          title={t("Common sense")}
          description={t("Let it guide you when things get complicated.")}
          backgroundColor={colors.watermelon}
          icon={CommonSense}
        />

        <Card
          title={t("Share knowledge")}
          description={t("Be generous with what you know.")}
          backgroundColor={colors.indiblue}
          icon={Share}
        />
        <Card
          title={t("Accountability matters")}
          description={t("Always take ownership of what you’re doing.")}
          backgroundColor={colors.cream}
          icon={Accountability}
        />
        <Card
          title={<BePositive />}
          description={t("Try to remain calm when problems arise.")}
          backgroundColor={colors.watermelon}
          icon={Positivity}
        />
        <Card
          title={t("Cultivate autonomy")}
          description={t("But also know when to ask for help.")}
          backgroundColor={colors.supernova}
          icon={Autonomy}
        />
        <Card
          title={
            <span>
              <Trans>Curiosity</Trans>
              <br />
              <Trans>is key</Trans>
            </span>
          }
          description={t("Strive to be up to date on current industry trends.")}
          backgroundColor={colors.indiblue}
          icon={Curiosity}
        />
        <Card
          title={t("Constant Improvement")}
          description={t("Going the extra mile really makes a difference.")}
          backgroundColor={colors.cream}
          icon={Improvement}
        />
        <Card
          title={t("Common sense")}
          description={t("Let it guide you when things get complicated.")}
          backgroundColor={colors.watermelon}
          icon={CommonSense}
        />
        <Card
          title={t("Share knowledge")}
          description={t("Be generous with what you know.")}
          backgroundColor={colors.indiblue}
          icon={Share}
        />
        <Card
          title={t("Accountability matters")}
          description={t("Always take ownership of what you’re doing.")}
          backgroundColor={colors.cream}
          icon={Accountability}
        />
        <Card
          title={<BePositive />}
          description={t("Try to remain calm when problems arise.")}
          backgroundColor={colors.watermelon}
          icon={Positivity}
        />
        <Card
          title={t("Cultivate autonomy")}
          description={t("But also know when to ask for help.")}
          backgroundColor={colors.supernova}
          icon={Autonomy}
        />
        <Card
          title={
            <span>
              <Trans>Curiosity</Trans>
              <br />
              <Trans>is key</Trans>
            </span>
          }
          description={t("Strive to be up to date on current industry trends.")}
          backgroundColor={colors.indiblue}
          icon={Curiosity}
        />
        <Card
          title={t("Constant Improvement")}
          description={t("Going the extra mile really makes a difference.")}
          backgroundColor={colors.cream}
          icon={Improvement}
        />
        <Card
          title={t("Common sense")}
          description={t("Let it guide you when things get complicated.")}
          backgroundColor={colors.watermelon}
          icon={CommonSense}
        />
        <Card
          title={t("Share knowledge")}
          description={t("Be generous with what you know.")}
          backgroundColor={colors.indiblue}
          icon={Share}
        />
        <Card
          title={t("Accountability matters")}
          description={t("Always take ownership of what you’re doing.")}
          backgroundColor={colors.cream}
          icon={Accountability}
        />
        <Card
          title={<BePositive />}
          description={t("Try to remain calm when problems arise.")}
          backgroundColor={colors.watermelon}
          icon={Positivity}
        />
        <Card
          title={t("Cultivate autonomy")}
          description={t("But also know when to ask for help.")}
          backgroundColor={colors.supernova}
          icon={Autonomy}
        />
        <Card
          title={
            <span>
              <Trans>Curiosity</Trans>
              <br />
              <Trans>is key</Trans>
            </span>
          }
          description={t("Strive to be up to date on current industry trends.")}
          backgroundColor={colors.indiblue}
          icon={Curiosity}
        />
        <Card
          title={t("Constant Improvement")}
          description={t("Going the extra mile really makes a difference.")}
          backgroundColor={colors.cream}
          icon={Improvement}
        />
        <Card
          title={t("Common sense")}
          description={t("Let it guide you when things get complicated.")}
          backgroundColor={colors.watermelon}
          icon={CommonSense}
        />
      </div>
    </StyledTogether>
  )
}

export default Together
