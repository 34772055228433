import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables"

// Components
import Container from "components/container/"

// Icons
import IconSocialMedia from "assets/icons/culture/icon-social-media.inline.svg"
import { Trans } from "react-i18next"

const StyledSocialMedia = styled.section`
  padding: 40px 0 76px 0;

  ${breakpoint.medium`
    padding: 88px 0 156px 0;
  `}

  h1 {
    margin-bottom: 24px;

    ${breakpoint.medium`
      margin-bottom: 48px;
    `}
  }

  .social-media__links {
    a {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 1.4em;
      transition: all 0.3s ease;

      &::after {
        content: "";
        width: 8px;
        height: 8px;
        display: inline-block;
        margin: 0 8px;
        background-color: ${colors.supernova};
        border-radius: 50%;

        ${breakpoint.medium`
          margin: 0 24px;
        `}
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .social-media__icon {
    max-width: 1070px;
    margin: 16px auto 0 auto;

    ${breakpoint.medium`
      margin-top: 96px;
    `}
  }
`

const SocialMedia = () => {
  const socialMediaProfiles = [
    {
      name: "Dribbble",
      url: "https://dribbble.com/Indicius",
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/indiciusar/",
    },
    {
      name: "LinkedIn",
      url: "https://ar.linkedin.com/company/indicius",
    },
    {
      name: "Medium",
      url: "https://medium.com/indicius",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/indicius",
    },
  ]

  return (
    <StyledSocialMedia className="bg--white color--midnight">
      <Container>
        <h1 className="text-center">
          <Trans>Check Out our Social Media</Trans>
        </h1>

        <div className="social-media__links d-flex flex-wrap align-items-center justify-content-center">
          {socialMediaProfiles.map(link => (
            <a
              key={link.name}
              href={link.url}
              className="d-inline-flex align-items-center color--indiblue color-hover--midnight font-weight--500"
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.name}
            </a>
          ))}
        </div>
      </Container>

      <div className="social-media__icon">
        <IconSocialMedia />
      </div>
    </StyledSocialMedia>
  )
}

export default SocialMedia
