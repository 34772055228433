import React, { useState } from "react"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

//Libraries
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"

// Components
import Container from "components/container/"
import Observer from "components/observer/"
import Grid from "components/grid/"
import Dropdown from "components/dropdown/"

// Icons
import Icon from "assets/icons/culture/half-circle.svg"

const StyledContainer = styled(Container)`
  max-width: 928px;
  opacity: ${props => (props.visible ? "1" : "0")};
  transform: ${props => (props.visible ? "translateY(0)" : "translateY(80px)")};
  transition: all 0.6s ease-in-out;
`

const StyledGrid = styled(Grid)`
  .grid__item {
    ${breakpoint.small`
      margin-bottom: 62px;
    `}
  }
`

const StyledMotion = styled.section`
  position: relative;
  padding-top: 80px;
  background-color: ${colors.midnight};
  color: ${colors.white};

  ${breakpoint.medium`
    padding-top: 160px;
  `}

  h1 {
    margin-bottom: 32px;
    font-family: ${fonts.secondary};
    font-weight: 400;
    text-align: center;

    ${breakpoint.medium`
      margin-bottom: 80px;
    `}
  }
`

const StyledIcon = styled.div`
  display: none;

  ${breakpoint.medium`
    position: absolute;
    top: 64px;
    left: 0;
    display: flex;
    align-items: flex-start;
    transform: ${props =>
      props.visible ? "translateX(0)" : "translateX(-80%)"};
    transition: all .6s;

    &.second-icon {
      top: 360px;
      right: 0;
      left: auto;
      transform: ${props =>
        props.visible ? "translateX(0)" : "translateX(80%)"};

      img {
        transform: rotate(180deg);
      }
    }
  `}
`

class MotionIcon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
  }

  handleVisibility = () => {
    if (!this.state.isVisible) {
      this.setState({
        isVisible: true,
      })
    }
  }

  render = props => (
    <Observer onVisible={this.handleVisibility} threshold="0.8">
      <StyledIcon
        className={this.props.className}
        visible={this.state.isVisible}
      >
        <img src={Icon} alt="Our Culture" />
      </StyledIcon>
    </Observer>
  )
}

const Motion = () => {
  const [isVisible, setIsVisible] = useState(false)

  const handleVisibility = () => {
    if (!isVisible) {
      setIsVisible({
        isVisible: true,
      })
    }
  }

  const { t } = useTranslation()

  return (
    <StyledMotion>
      <MotionIcon />
      <MotionIcon className="second-icon" />
      <Observer onVisible={handleVisibility} threshold="0.3">
        <StyledContainer visible={isVisible}>
          <h1>
            <Trans>The Indi process</Trans>
          </h1>
          <StyledGrid gutter="32px" columns="2">
            <div className="grid__item">
              <Dropdown
                title={t("Tailored")}
                subtitle={t("Focused on the client's needs")}
              >
                <p>
                  <Trans>
                    Human-centered processes mean each project is different.
                    Don’t be shy, we’ll be asking lots of questions about your
                    project and goals.
                  </Trans>
                </p>
                <br />
                <p>
                  <Trans>
                    We help clients define and execute a solid strategy for all
                    stages of their digital products because we want their ideas
                    to be successful.
                  </Trans>
                </p>
              </Dropdown>
            </div>
            <div className="grid__item">
              <Dropdown
                title={t("Functional")}
                subtitle={t("User centered & accessible")}
              >
                <p>
                  <Trans>
                    We’re known for being amazing visual creators! But
                    interfaces aren’t just canvases to draw pretty things on and
                    post to Dribbble. We research and test to understand users’
                    needs.
                  </Trans>
                </p>
              </Dropdown>
            </div>
            <div className="grid__item">
              <Dropdown
                title={t("Measurable")}
                subtitle={t("We track progress by testing")}
              >
                <p>
                  <Trans>
                    We’ve evolved from measuring success by “we built something“
                    to “we made a user more successful”. Insights from user
                    testing and research are how we know if something works.
                  </Trans>
                </p>
              </Dropdown>
            </div>
            <div className="grid__item">
              <Dropdown
                title={t("Strategic")}
                subtitle={t("We define design strategies")}
              >
                <p>
                  <Trans>
                    We start each project by analyzing the best process and
                    tools for the task at hand.
                  </Trans>
                </p>
              </Dropdown>
            </div>
            <div className="grid__item">
              <Dropdown
                title={t("Agile")}
                subtitle={t("We constantly evolve our solutions")}
              >
                <p>
                  <Trans>
                    Agile is both a cultural mindset and the way we work on a
                    daily basis. We aim to eliminate separation between
                    developers and designers for multidisciplinary,
                    collaborative teams that create great results.
                  </Trans>
                </p>
              </Dropdown>
            </div>
            <div className="grid__item">
              <Dropdown
                title={t("Multicultural")}
                subtitle={t("We produce global digital products")}
              >
                <p>
                  <Trans>
                    We're not constrained by where we're from. We work with
                    clients from all over the world, which has given us the
                    flexibility to adapt to their needs and cater to a
                    multicultural audience.
                  </Trans>
                </p>
              </Dropdown>
            </div>
          </StyledGrid>
        </StyledContainer>
      </Observer>
    </StyledMotion>
  )
}

export default Motion
